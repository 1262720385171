import React from "react";
import { useDispatch, useSelector } from "react-redux";
import { Link } from "gatsby";
import { removeGuide } from "../../actions"; 
import { Badge, Dropdown, GuideBadge } from "../../common";

const GuideList = ({ guides, updateModal }) => {
  const dispatch = useDispatch();

  function handleDelete(id) {
    dispatch(removeGuide(id));
  }

  function returnGuides() {
    return (
      <div className="overflow-hidden bg-white shadow sm:rounded-md">
        <ul role="list" className="divide-y divide-gray-200">
          {guides.map((guide) => {
            const text_to_return =
              guide.text.length > 100
                ? guide.text.substring(0, 120) + "..."
                : guide.text;
            return (
              <li style={{ cursor: "pointer" }} key={guide.id}>
                <div
                  onClick={() => updateModal(guide)}
                  className="block hover:bg-gray-50"
                  //to={"/guides/view?id=" + guide.id}
                >
                  <div className="px-4 py-4 sm:px-6">
                    <div className="flex items-center justify-between">
                      <p className="truncate text-sm font-medium text-indigo-600">
                        {guide.title}
                      </p>
                      <div className="ml-2 flex flex-shrink-0">
                        {/* {returnTypeTag(guide.type)} */}
                        <GuideBadge type={guide.type} />
                      </div>
                    </div>
                    <div className="mt-2 sm:flex sm:justify-between">
                      <div className="sm:flex">
                        <p className="flex items-center text-sm text-gray-500">
                          <div
                            dangerouslySetInnerHTML={{ __html: text_to_return }}
                          ></div>
                        </p>
                      </div>
                      <div className="mt-2 flex items-center text-sm text-gray-500 sm:mt-0">
                        {/* <svg
                          xmlns="http://www.w3.org/2000/svg"
                          viewBox="0 0 24 24"
                          fill="currentColor"
                          class="w-5 h-5"
                          style={{ marginRight: 5 }}
                        >
                          <path
                            fill-rule="evenodd"
                            d="M6.75 2.25A.75.75 0 017.5 3v1.5h9V3A.75.75 0 0118 3v1.5h.75a3 3 0 013 3v11.25a3 3 0 01-3 3H5.25a3 3 0 01-3-3V7.5a3 3 0 013-3H6V3a.75.75 0 01.75-.75zm13.5 9a1.5 1.5 0 00-1.5-1.5H5.25a1.5 1.5 0 00-1.5 1.5v7.5a1.5 1.5 0 001.5 1.5h13.5a1.5 1.5 0 001.5-1.5v-7.5z"
                            clip-rule="evenodd"
                          />
                        </svg> */}

                        {/* <p>
                          Created:
                          <time dateTime={guide.createdAt}>
                            {Moment(guide.createdAt).format("MMM Do YYYY")}
                          </time>
                        </p> */}

                        <Link to={"/guides/edit?id=" + guide.id}>
                          {" "}
                          <svg
                            xmlns="http://www.w3.org/2000/svg"
                            fill="none"
                            viewBox="0 0 24 24"
                            strokeWidth={1.5}
                            stroke="currentColor"
                            className="w-6 h-6"
                          >
                            <path
                              strokeLinecap="round"
                              strokeLinejoin="round"
                              d="M16.862 4.487l1.687-1.688a1.875 1.875 0 112.652 2.652L10.582 16.07a4.5 4.5 0 01-1.897 1.13L6 18l.8-2.685a4.5 4.5 0 011.13-1.897l8.932-8.931zm0 0L19.5 7.125M18 14v4.75A2.25 2.25 0 0115.75 21H5.25A2.25 2.25 0 013 18.75V8.25A2.25 2.25 0 015.25 6H10"
                            />
                          </svg>
                        </Link>
                      </div>
                    </div>
                  </div>
                </div>
              </li>
            );
          })}
        </ul>
      </div>
    );
  }

  try {
    return <div>{returnGuides()}</div>;
  } catch (error) {
    return <div>{JSON.stringify(guides)}</div>;
  }
};

export default GuideList;
