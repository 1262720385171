import React, { Fragment, useState } from "react";
import { Dialog, Transition } from "@headlessui/react";
const TriageView = ({ open, view_modal_data, update_view_modal_open }) => {
  const [view, update_view] = useState("main");

  function closeModal() {
    update_view_modal_open(false);
    update_view("main");
  }

  function returnModal() {
    var guide = view_modal_data;
    if (view_modal_data === null) {
      return <></>;
    }

    function returnTriage() {
      const data = JSON.parse(guide.data);
      var ClassNameHeader = "routine triageHeader";
      switch (data.id) {
        case 1:
          // Routine
          ClassNameHeader = "routine triageHeader";
          break;
        case 2:
          // Soon
          ClassNameHeader = "soon triageHeader";
          break;

        case 3:
          // Urgent
          ClassNameHeader = "urgent triageHeader";
          break;

        default:
          break;
      }
      return (
        <div className={ClassNameHeader}>
          <div
            onClick={() => update_view("main")}
            className="triageHeaderTitle"
          >
            Triage: {data.name}
          </div>
          <div
            className="textPromptButton"
            onClick={() => update_view("text_message")}
          >
            <svg
              xmlns="http://www.w3.org/2000/svg"
              fill="none"
              viewBox="0 0 24 24"
              strokeWidth={1.5}
              stroke="currentColor"
              className="w-6 h-6"
            >
              <path
                strokeLinecap="round"
                strokeLinejoin="round"
                d="M2.25 12.76c0 1.6 1.123 2.994 2.707 3.227 1.087.16 2.185.283 3.293.369V21l4.076-4.076a1.526 1.526 0 011.037-.443 48.282 48.282 0 005.68-.494c1.584-.233 2.707-1.626 2.707-3.228V6.741c0-1.602-1.123-2.995-2.707-3.228A48.394 48.394 0 0012 3c-2.392 0-4.744.175-7.043.513C3.373 3.746 2.25 5.14 2.25 6.741v6.018z"
              />
            </svg>
          </div>
        </div>
      );
    }

    function returnTriagePrompt() {
      return (
        <div className="mt-1 flex justify-center rounded-md border-2 border-dashed redboarder margin15">
          <div
            className="triageBlockPrompt"
            dangerouslySetInnerHTML={{
              __html: guide.data2,
            }}
          />
        </div>
      );
    }

    function returnView() {
      switch (view) {
        case "main":
          return (
            <div>
              <div className="mt-3 text-center sm:mt-5">
                <Dialog.Title
                  as="h3"
                  className="text-lg font-medium leading-6 text-gray-900"
                >
                  {guide.title}
                </Dialog.Title>

                <div className="mt-2">
                  <p
                    className="text-sm text-gray-500 padding10"
                    dangerouslySetInnerHTML={{
                      __html: guide.text,
                    }}
                  />
                </div>
                <div>{returnTriagePrompt()}</div>
              </div>
            </div>
          );
          break;
        case "text_message":
          return (
            <div className="padding10">
              <div className="sm:col-span-6">
                <Dialog.Title
                  as="h3"
                  style={{ textAlign: "center" }}
                  className="text-lg font-medium leading-6 text-gray-900 padding10"
                >
                  SMS Message
                </Dialog.Title>
                <div className="mt-1 flex justify-center rounded-md border-2 border-dashed border-gray-300 px-6 pt-5 pb-6">
                  <div className="space-y-1 text-center">
                    <div className="flex text-sm text-gray-600">
                      <p className="pl-1">{guide.data3}</p>
                    </div>
                  </div>
                </div>
              </div>
              <button
                type="button"
                className="marginTop15 inline-flex w-full justify-center rounded-md border border-transparent bg-indigo-600 px-4 py-2 text-base font-medium text-white shadow-sm hover:bg-indigo-700 focus:outline-none focus:ring-2 focus:ring-indigo-500 focus:ring-offset-2 sm:text-sm"
              >
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  fill="none"
                  viewBox="0 0 24 24"
                  stroke-width="1.5"
                  stroke="currentColor"
                  class="w-5 h-5"
                >
                  <path
                    stroke-linecap="round"
                    stroke-linejoin="round"
                    d="M15.666 3.888A2.25 2.25 0 0013.5 2.25h-3c-1.03 0-1.9.693-2.166 1.638m7.332 0c.055.194.084.4.084.612v0a.75.75 0 01-.75.75H9a.75.75 0 01-.75-.75v0c0-.212.03-.418.084-.612m7.332 0c.646.049 1.288.11 1.927.184 1.1.128 1.907 1.077 1.907 2.185V19.5a2.25 2.25 0 01-2.25 2.25H6.75A2.25 2.25 0 014.5 19.5V6.257c0-1.108.806-2.057 1.907-2.185a48.208 48.208 0 011.927-.184"
                  />
                </svg>
                Copy to clipboard
              </button>
            </div>

            //   <div>SMS Message:</div>
            //   <div>{guide.data3}</div>
          );
          return <div>{guide.data3}</div>;
        default:
          break;
      }
    }
    return (
      <Transition.Root show={open} as={Fragment}>
        <Dialog as="div" className="relative z-10" onClose={closeModal}>
          <Transition.Child
            as={Fragment}
            enter="ease-out duration-300"
            enterFrom="opacity-0"
            enterTo="opacity-100"
            leave="ease-in duration-200"
            leaveFrom="opacity-100"
            leaveTo="opacity-0"
          >
            <div className="fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity" />
          </Transition.Child>

          <div className="fixed inset-0 z-10 overflow-y-auto">
            <div className="flex min-h-full items-end justify-center p-4 text-center sm:items-center sm:p-0">
              <Transition.Child
                as={Fragment}
                enter="ease-out duration-300"
                enterFrom="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
                enterTo="opacity-100 translate-y-0 sm:scale-100"
                leave="ease-in duration-200"
                leaveFrom="opacity-100 translate-y-0 sm:scale-100"
                leaveTo="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
              >
                <Dialog.Panel className="removePadding relative transform overflow-hidden rounded-lg bg-white px-4 pt-5 pb-4 text-left shadow-xl transition-all sm:my-8 sm:w-full sm:max-w-sm sm:p-6">
                  <div>{returnTriage()}</div>
                  {returnView()}
                  {/* <div className="mt-5 sm:mt-6">
                    <button
                      type="button"
                      className="inline-flex w-full justify-center rounded-md border border-transparent bg-indigo-600 px-4 py-2 text-base font-medium text-white shadow-sm hover:bg-indigo-700 focus:outline-none focus:ring-2 focus:ring-indigo-500 focus:ring-offset-2 sm:text-sm"
                      onClick={() => update_view_modal_open(false)}
                    >
                      Go back
                    </button>
                  </div> */}
                </Dialog.Panel>
              </Transition.Child>
            </div>
          </div>
        </Dialog>
      </Transition.Root>
    );
  }

  return <div>{returnModal()}</div>;
};

export default TriageView;
