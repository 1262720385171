import React, { Fragment, useState } from "react";
import { Dialog, Transition } from "@headlessui/react";
import TriageView from "./types/triageView";
import ContactView from "./types/contactView";

const ViewModal = ({ open, view_modal_data, update_view_modal_open }) => {
  var guide = view_modal_data;
  if (view_modal_data === null) {
    return <></>;
  }

  switch (guide.type) {
    case "Triage":
      return (
        <TriageView
          open={open}
          view_modal_data={guide}
          update_view_modal_open={update_view_modal_open}
        ></TriageView>
      );
      break;
    case "Contact":
      return (
        <ContactView
          open={open}
          view_modal_data={guide}
          update_view_modal_open={update_view_modal_open}
        ></ContactView>
      );
      break;
    default:
      return <div>nill</div>;
      break;
  }
};

export default ViewModal;
